.app {
  position: relative;
  min-height: 100vh;
}

.wrapper {
  padding: 16px;
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  background-color: #0080a3;
  padding: 16px;
  color: #ffffff;
}

.header svg {
  font-size: 2.5rem;
  margin-right: 8px;
}

.bin-card {
  display: flex;
  align-items: center;
  background-color: #efefef;
  margin: 0;
  font-size: 1.25rem;
  border-bottom: 1px solid #d9dde7;
  color: #000;
  padding: 16px;
  margin-top: 16px;
}

.bin-card img {
  width: 62px;
  height: 83px;
  margin-right: 16px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0080a3;
  padding: 8px;
}

.footer img {
  width: 50px;
}

.chevron {
  font-size: 2rem;
  display: block;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
}